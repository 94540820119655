@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

* {
  font-family: 'Oswald', sans-serif;
}

.fade-animation {
  animation: fade 0.5s ease;
}

.nav-menu-btn {
  display: none;
}

.navbar-list {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a{
  color: #000000;
}

button {
  transition: all .2s ease-in-out;
  cursor: pointer;
}

button:hover {
  transform: scale(1.05);
}

.navbar {
  z-index: 999;
  overflow: hidden;
  top: 0;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2fce3;
  height: 12vh;
}

.logo {
  font-size: 3em;
  text-decoration: none;
  color: #000000;
  margin-left: 1em;
}

.menu {
  display: flex;
  width: 50vw;
  justify-content: space-around;
  font-size: 1.7em;
  font-weight: 300;
  margin-right: 4vw;
}

.contact-btn {
  width: 8vw;
  height: 6vh;
  margin-right: 2em;
  background-color: #8FC93A;
  border: none;
  font-size: 1.5em;
}

.main {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2vh
}

.main-head {
  display: flex;
  gap: 2vw;
  margin-top: 4vh;
}

.main-img {
  height: 30vh;
}

.main-text {
  font-size: 8em;
  text-align: center;
}

.main-desc {
  width: 40vw;
  font-weight: 300;
  font-size: 2em;
  text-wrap: wrap;
}

.main-button {
  display: flex;
  width: 80vw;
  justify-content: space-around;
  align-items: center;
}

.info-btn {
  width: 25vw;
  height: 10vh;
  border: none;
  font-size: 2em;
  background-color: #8FC93A;
}

.offer {
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-head {
  text-align: center;
  color:#8FC93A;
  font-size: 4em;
  font-weight: 600;
  margin-bottom: 3vh;
}

.offer-info {
  text-align: center;
  font-weight: 200;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
}

ul {
  text-align: left;
}

.offer-tiles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6vh;
  flex-direction: column;
  margin-top: 4vh;
}

.work-img {
  height: auto;
  max-height: 50vh;
  width: auto;
  border-radius: 12px;
  transition: all .2s ease-in-out;
}

.work-img-main {
  max-height: 70vh;
  border-radius: 12px;
}

.offer-1 {
  padding: 1.5em;
  min-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #393939;
  border-radius: 12px;
  box-shadow: -8px 12px 6px 0px rgba(0, 0, 0, 0.35);
  gap: 3vh;
  color: white;
  font-size: 2em;
  font-weight: 300;
}

.work-img-horizontal {
  max-width: 35vw;
  transition: all .2s ease-in-out;
  border-radius: 12px;
}

.offer-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 50vw;
  align-items: center;
  gap: 3vh;
  padding: 1.5em;
  background-color: #393939;
  border-radius: 12px;
  box-shadow: -8px 12px 6px 0px rgba(0, 0, 0, 0.35);
  color: white;
  font-size: 2em;
  font-weight: 300;
}

.tile-imgs {
  display: flex;
  gap: 4vw;
  margin-bottom: 2vh;
}

.work-img-horizontal:hover {
  transform: scale(1.06);
}

.work-img:hover {
  transform: scale(1.06);
}

.work {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
}

.work-head {
  text-align: center;
  color:#8FC93A;
  font-size: 4em;
  font-weight: 600;
  margin-bottom: 3vh;
}

.work-desc {
  font-size: 2em;
  font-weight: 200;
}

.work-img-slider {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vh;
}

.arrow-btn-left, .arrow-btn-right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8FC93A;
  border: none;
  border-radius: 12px;
  color:#000000;
  width: 4vw;
  height: 8vh;
}

.arrow-img-left, .arrow-img-right {
  width: 3.5vw;
}

.arrow-img-left {
  transform: rotate(180deg);
}

.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 8vh;
}

.reviews-head {
  text-align: center;
  color:#8FC93A;
  font-size: 4em;
  font-weight: 600;
  margin-top: 4vh;
  margin-bottom: 3vh;
}

.reviews-desc {
  font-size: 2em;
  font-weight: 200;
}

.reviews-slider {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vh;
}

.review-tile {
  color: white;
  background-color: #393939;
  width: 40vw;
  height: 30vh;
  border-radius: 12px;
  box-shadow: -8px 12px 6px 0px rgba(0, 0, 0, 0.35);
  padding: 1em;
}

.star-img {
  width: 2vw;
}

.review-tile-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2em;
  border-bottom: 3px white solid;
}

.review-tile-desc {
  padding: 0.5vh;
  margin-top: 2vh;
  font-weight: 300;
  font-size: 1.5em;
}

.contact {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.contact-head {
  text-align: center;
  color:#8FC93A;
  font-size: 4em;
  font-weight: 600;
  margin-top: 8vh;
  margin-bottom: 5vh;
}

.contact-desc {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10vh;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.8em;
  font-weight: 300;
  gap: 1.5em;
}

.footer {
  width: 100vw;
  height: 20vh;
  display: flex;
  align-items: start;
  gap: 6vw;
  background-color: #8FC93A;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
}

.footer-contact, .footer-menu {
  margin-top: 2vh;
}

.footer-menu-div1, .footer-menu-div2 {
  display: flex;
  flex-direction: column;
}

.footer-menu-divider {
  display: flex;
  gap: 3vw;
}

@media (max-width: 1070px) {
  .nav-menu-btn {
    display: block;
    width: 5vw;
    padding: 1em;
    margin-right: 2vw;
  }

  .menu, .nav-button, .contact-btn {
    display: none;
  }

  .nav-menu-btn-list {
    display: block;
    width: 5vw;
  }

  .navbar-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    right: 0;
    top: 0;
    width: 0vw;
    opacity: 0.96;
    height: 100vh;
    z-index: 999999;
    position: fixed;
    background-color: #8FC93A;
    transition: all .4s ease;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    padding: 1em;
    font-size: 1.8em;
    gap: 3vh;
    transition: all .4s ease;
  }

  .contact-btn-list {
    width: 45vw;
    height: 8vh;
    border: none;
    background-color: #000000;
    color: #8FC93A;
    margin-bottom: 14vh;
    margin-left: 18vw;
    transition: all .4s ease;
  }

  .logo {
    font-size: 2em; 
  }

  .main {
    gap: 0;
  }

  .main-head {
    align-items: center;
    gap: 0;
    justify-content: space-evenly;
  }

  .main-text {
    font-size: 3.5em;
  }

  .main-img {
    height: 20vh;
  }

  .main-desc {
    width: 85vw;
    font-size: 1.5em;
  }

  .main-button {
    width: 100vw;
  }

  .info-btn {
    width: 35vw;
    font-size: 1.2em;
  }

  .offer-info {
    font-size: 1.2em;
  }

  .offer-head {
    font-size: 3em;
  }

  .work-img-horizontal {
    max-width: 60vw;
  }

  .work-head {
    font-size: 3em;
  }

  .work-desc {
    font-size: 1.2em;
  }

  .work-img-main {
    max-height: 40vh;
  }

  .work-img-slider {
    width: 90vw;
  }

  .arrow-btn-left, .arrow-btn-right {
    width: 10vw;
  }

  .reviews-head {
    font-size: 3em;
  }

  .reviews-desc {
    font-size: 1.2em;
  }

  .reviews-slider {
    width: 90vw;
  }

  .review-tile {
    width: 55vw;
  }

  .review-tile-head {
    font-size: 1.3em;
  }

  .star-img {
    width: 6vw;
  }

  .review-tile-desc {
    font-size: 1em;
  }

  .contact-head {
    font-size: 3em;
  }

  .contact-desc {
    flex-direction: column;
  }

  iframe {
    width: 80vw;
  }

  .contact-info {
    font-size: 1.2em;
    margin-top: 2vh;
  }

  .footer {
    height: auto;
  }

  .footer-contact {
    padding: 0.5em;
  }

  .footer-menu {
    padding: 0.5em;
  }

  .footer-menu-divider {
    flex-direction: column;
    gap: 0;
  }

  .info-btn {
    color: black;
  }

  a {
    color: #000000;
  }

  a:visited{
    color: #000000;
  }
}

@media (max-width: 1300px) {
  .tile-imgs {
    flex-direction: column;
  }

  .offer-1 {
    height: auto;
  }

  .offer-2 {
    height: auto;
  }

  .offer {
    height: auto;
  }

  .offer-tiles {
    height: auto;
  }

  .work-img {
    max-height: 35vh;
  }
}